import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {API_EVENT_BY_NAME, EVENT_IMG_URL} from "../../config/urls";
import {EventModel} from "../../models/event";
import EventPanel from "./View/EventPanel";
import TGButton from "../../components/Button/Button";
import EventTitle from "./View/EventTitle";

import {BackButton} from '@vkruglikov/react-telegram-web-app';

const FeedView = () => {
    let {name} = useParams();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    // get start date from url
    const startString = searchParams.get("start")
    const startDateParam = startString ? new Date(startString) : null;

    const [data, setData] = useState<EventModel>();
    //const [data, setData] = useState<Product>();
    console.log(name, searchParams.get("start"))
    console.log(typeof searchParams.get("start"))
    useEffect(() => {
        fetch(API_EVENT_BY_NAME + "/?url=" + name)
            .then(response => response.json())
            .then(data => {
                    setData(data.data)
                    //console.log(data.data)
                }
            )
    }, [name])


    return (
        <div className="h-100">
            <BackButton
                onClick={() => {
                    navigate("/events")
                }}
            />
            {/*

                <div className="event-panel-container">
                    <EventPanel {...data} start={startDateParam ?? data?.start}/>
                </div>
*/}


            {/*   <EventTitle
                    name={data?.name}
                    eventType={data?.eventType}
                    start={startDateParam ?? data?.start}
                />
            */}
            <div className={""} style={{
                backgroundImage: `url('${EVENT_IMG_URL}${data?.image_name}')`,
                backgroundSize: "cover"
            }}>
                <EventTitle
                    name={data?.name}
                    eventType={data?.eventType}
                    start={startDateParam ?? data?.start}
                />

                <br/>
            </div>
            <br/>


            <div className="card p-3">
                <TGButton to={"/events"}>Назад</TGButton>
                <TGButton to={"/events"}>Присоединиться</TGButton>
                <br/>
                <EventPanel {...data} start={startDateParam ?? data?.start}/>
                <div className="event" itemScope>

                    <div className="event-content-container">
                        <div className="event-content">
                            <div itemProp="description text-left"
                                 dangerouslySetInnerHTML={{__html: data?.description ?? ''}}/>
                            {/*      <Gratitude links={['zovem.ru', 'tandp.ru', '2do2go.ru', '2anticafe.com']}/>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedView;