import React from "react";
import ShopListCard from './ShopListCard';
import Spinner from "../../components/Spinner";
import InfiniteScroll from 'react-infinite-scroller';
import {observer} from "mobx-react";
import {productStore} from "./store/ProductStore";
import {Row} from "react-bootstrap";

const ShopList = observer(() => {

    const [searchTerm, setSearchTerm] = React.useState("");
    const handleChange = (event: any) => {
        setSearchTerm(event.target.value);
        //console.log(event.target.value);
    };

    React.useEffect(() => {
        productStore.search(searchTerm);
    }, [searchTerm]);

    return (
        <>
            <InfiniteScroll
                pageStart={0}
                loadMore={productStore.loadMore}
                hasMore={productStore.hasMore}
                loader={<Spinner/>}>
                <section className="section-products">
                    <Row>
                        <input type="text"
                               className="form-control ml-3 mr-3 mb-2"
                               placeholder="Поиск..."
                               value={searchTerm}
                               onChange={handleChange}
                        />
                    </Row>
                    <div className="row row-cols-1 row-cols-sm-1  row-cols-md-2 row-cols-lg-4">
                        {
                            // (productStore.items.length > 0) ?
                            productStore.items.map((item: any) => (
                                <ShopListCard key={item.id} product={item}/>
                            ))
                            //  : <div>Пусто</div>
                        }
                    </div>
                </section>
            </InfiniteScroll>
        </>
    )

});

export default ShopList;