//const KassaCheckout = (id: number, checkoutType: CheckoutType): Promise<any> => {
import {ApiRequest, HttpMethod} from "./api";
import stringify from "qs-stringify";

type Params = {
    user_id: number,
    table_game_id: number,
    players_from: number,
    players_to: number,
    name: string,
    descr: string,
    short_descr: string,
    from_dt: string,
    to_dt: string,
    status: number, //0-создано, -1-удалено, 1-активно, 10-выключено
}
export const CreateGameEvent = (params: Params): Promise<any> => {
    return ApiRequest<any>(HttpMethod.POST, "/rapi/user-table-game-events/create/", stringify(params));
};
