import axios from "axios";
import {Constants} from "../utils/constants";

//import botLog from "../utils/logger";

export enum HttpMethod {
    GET = "get",
    POST = "post",
    //PUT = "put",
    DELETE = "delete",
}


export function ApiRequest<T>(method: HttpMethod, url: string, params?: any): Promise<T> {
    const headers = {
        'authorization': "Bearer " + Constants.ZDVER_API_TOKEN
    }
    console.log(headers)

    if (method === HttpMethod.GET) {
        return axios.get<T>(Constants.API_URL + url, {headers: headers, params: params})
            .then((response) => response.data)
            .catch((error) => {
                GlobalOnError(error);
                throw error;
            })

    } else { //if (method === HttpMethod.POST) {
        return axios.post(Constants.API_URL + url, params, {headers})
            .then((response) => response.data)
            .catch((error) => {
                GlobalOnError(error);
                throw error
            });
    }
}

export const GlobalOnError = (e: any) => {
    //botLog(e, "error")
    /*if (e.response.status === 401) {
        botLog("API 401!!!", "error")
    }*/
}