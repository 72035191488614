import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug} from "@fortawesome/free-solid-svg-icons";

class Help extends React.Component {
    render() {
        return (
            <div className="h-100">
                <h1>Раздел помощи в разработке</h1>
                <h5>Скоро мы его доделаем</h5>
                <FontAwesomeIcon size="3x" icon={faBug}/>
            </div>
        )
    }
}

export default Help;
