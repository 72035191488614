import React from "react";

class Rules extends React.Component {
    render() {
        return (
            <div className="h-100">
                <h1>Правила заведения</h1>
                <ul className="text-left">
                    <li>Мы работаем 24/7. И даже в новогоднюю ночь. Если что-то меняется, то мы про это напишем во всех
                        наших соцсетях.
                    </li>
                    <li>Оплата у нас поминутная. Т.е если вы зашли к нам на 10 минут, то заплатите за 10 минут. Тариф
                        3р/мин первые два часа, 2р/мин в последующие. Действуют стопчеки, т.е. сумма больше которой вы
                        не заплатите, сколько бы времени не провели в нашем антикафе в пределах одних ЗД-суток (с 9.00
                        до 9.00): для школьников/студентов/аспирантов/курсантов 490р, для прочих категорий 690р.
                    </li>
                    <li>В нашем антикафе запрещено находиться в нетрезвом виде и распивать алкоголь. Если от вас
                        “пахнет”, то мы безапелляционно откажем вам в посещении.
                    </li>
                    <li>Двор тоже часть заведения.</li>
                    <li>Приставки мы не бронируем, но у нас их много :)</li>
                    <li>Все услуги антикафе, как чай-кофе-лимонады-настолки-конфеты-печенья, бесплатные. Изредка бывают
                        мероприятия с дополнительной оплатой, об этом мы всегда пишем заранее.
                    </li>
                    <li>В Зеленой Двери живет кошка. Мы ее очень любим и уважаем ее личное пространство. Пожалуйста, не
                        трогайте ее, если она сама к вам не подойдет.
                    </li>
                    <li>Мы очень просим наших гостей не материться. Даже если для вас это нормально, окружающих
                        ненормативная лексика может смущать.
                    </li>
                    <li>В нашем заведении множество музыкальных инструментов. И всеми можно пользоваться. Однако
                        уважайте покой окружающих: поинтересуйтесь, не против ли они нарушения их покоя.
                    </li>
                </ul>
            </div>
        )
    }
}

export default Rules;
