import React, {DispatchWithoutAction, FC, useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";
import Form from "./pages/form/Form";
import Menu from "./pages/Menu/Form";
import Shop from "./pages/games/Shop";
import ShopView from "./pages/games/view/ShopView";
import Feed from "./pages/Feed/Feed";
import FeedView from "./pages/Feed/FeedView";
import NotFound from "./pages/404";
import Rules from "./pages/Rules/Rules";
import Help from "./pages/Help/Help";
import Booking from "./pages/Booking/Booking";
import Pay from "./pages/pay/pay";
import {TG} from "./hooks/TelegramType";
import BackButtonDemo from './BackButtonDemo';
import {
    useThemeParams,
    WebAppProvider,BackButton,useShowPopup
} from '@vkruglikov/react-telegram-web-app';
import GameEvent from "./pages/gameEvent/GameEvent";
import GameEventCreated from "./pages/gameEvent/GameEventCreated";

const App2: FC<{
    onChangeTransition: DispatchWithoutAction;
}> = ({ onChangeTransition }) => {
   // const {onToggleButton, tg} = useTelegram();
    const tg:TG = window?.Telegram?.WebApp;
    const showPopup = useShowPopup();
    const [colorScheme, themeParams] = useThemeParams();

    const [activeBtn, setActiveBtn] = useState(true);

/*
    tg.ThemeParams.bg_color

    tg.ThemeParams.hint_color
    tg.ThemeParams.link_color
    tg.ThemeParams.button_color
    tg.ThemeParams.button_text_colorString*/

    useEffect(() => {
        tg?.ready();

    }, [])

    return (
        <div>
            <div className="App">

                {/*<Header />*/}
                <Routes>
                    <Route index element={<Menu/>}/>
                    <Route path={'form'} element={<Form/>}/>
                    <Route path={'menu'} element={<Menu/>}/>
                    <Route path={'games'} element={<Shop/>}/>
                    <Route path={'rules'} element={<Rules/>}/>
                    <Route path={'pay'} element={<Pay/>}/>
                    <Route path={'booking'} element={<Booking/>}/>
                    <Route path={'help'} element={<Help/>}/>
                    <Route path="/games/:id" element={<ShopView/>}/>
                    <Route path="/gameEvent/:id" element={<GameEvent/>}/>
                    <Route path="/gameEventCreated" element={<GameEventCreated/>}/>
                    <Route path="events" element={<Feed/>}/>
                    <Route path="/events/:name" element={<FeedView/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </div>
        </div>
    );
}

const App = () => {
    const [smoothButtonsTransition, setSmoothButtonsTransition] = useState(false);

    return (
        <WebAppProvider options={{ smoothButtonsTransition }}>
            <App2
                onChangeTransition={() => setSmoothButtonsTransition(state => !state)}
            />
        </WebAppProvider>
    );
};
export default App;
