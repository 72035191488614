import {action, makeAutoObservable, observable, runInAction} from 'mobx'
import {URL_SHOP_GET_ALL} from "../../../config/urls";
import {filterStore} from "./FilterStore";


export class ProductStore {
    @observable items: any[] = [];
    @observable hasMore: boolean = false;
    @observable page: number = 1;
    @observable query: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    @action
    clearItems() {
        //console.log("clearItems");
        this.items = [];
        this.page = 1;
        this.hasMore = true;
    }

    @action
    search = async (q: string) => {
        this.clearItems();
        filterStore.setSearchQuery(q);
    }

    @action
    loadMore = async () => {
        const data = await this.fetchProduct();
        //console.log(data)
        if (data.data.length > 0) {
            //console.log("data != null")
            //console.log(data.data.length)
            runInAction(() => {
                this.items.push(...data.data);
                if (data.pagination.last) {
                    this.hasMore = false;
                } else {
                    this.hasMore = true;
                    this.page++;
                }
                /*  if (data?.length < PRODUCT_PER_PAGE) {

                      this.hasMore = false;
                  } else {

                      this.hasMore = true;
                      this.page++;
                  }*/
            });
        } else {
            this.hasMore = false;
            //console.log("DATA-NULL")
        }

    }


    async fetchProduct() {
        //console.log("fetchProjects")
        try {
            const url = URL_SHOP_GET_ALL + '?' + filterStore.filterParams + "&page=" + this.page + "&pageSize=16&sort=-bgg_rate_average&is_active=1";
            const response = await fetch(url);
            // const json = await response.json();
            //return json.data;
            return response.json();

        } catch (e) {
            console.log(e)
        }
    }

    /*    loadMore = () => {
            const url = URL_SHOP_GET_ALL + filterStore.filterParams + "&page=" + this.page;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    console.log(data);

                }).then(runInAction);
        }*/

    /*    async loadMore() {
            const data = await this.fetchProduct()
            //if (res.status === 0) {
                runInAction(() => {
                    console.log(data);
                    data.data && this.items.push(data.data)
                    this.page++;
                    this.hasMore = true;

                    //this.monthList = res.data
                    //this.selectedMonth = res.data.length > 0 && res.data[0]
                })
           // }
        }*/
}

export const productStore = new ProductStore()