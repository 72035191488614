import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {GAMES_IMG_URL, URL_SHOP_GET_BY_ID} from "../../../config/urls";
import {Product} from "../../../models/product";
import ImageGallery from "react-image-gallery";
import PriceSelector from "../components/PriceSelector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ComplexityData from '../../../config/complexity.json';
import DurationData from '../../../config/duration.json';
import {BackButton} from '@vkruglikov/react-telegram-web-app';

/*import {  ViewMode, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
*/
//import { MobilePDFReader } from 'react-pdf-viewer';
import {
    faArrowUpRightFromSquare,
    faChartSimple,
    faCheck,
    faClock,
    faHashtag,
    faMasksTheater,
    faPeopleCarryBox,
    faPeopleGroup,
    faStar,
    faTable,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import CheckoutModal from "./CheckoutModal";
import StatusModal from "./StatusModal";
import ShopListMiniCard from "./ShopListMiniCard";

const ShopView = () => {
    let {id} = useParams();
    const navigate = useNavigate();

    const [images, setImages] = useState<any>([])
    const [data, setData] = useState<Product | null>(null);
    const [checkoutModalShow, setCheckoutModalShow] = React.useState(false);
    const [statusModalShow, setStatusModalShow] = React.useState(false);
    const [statusModalData, setStatusModalData] = React.useState();
    const [selectedPeriod, setSelectedPeriod] = React.useState(null);

    const getBggRate = () => {
        if (typeof data?.bgg_rate_average === 'number') {
            return data?.bgg_rate_average?.toFixed(1) ?? 0;
        } else if (typeof data?.bgg_rate_average === 'string') {
            const rate = parseFloat(data.bgg_rate_average);
            return rate ? rate.toFixed(1) : 0;
        } else {
            return 0;
        }
    };


    function getBggColor() {
        const rate = data?.bgg_rate_average ?? 0
        if (rate < 5) {
            return "#db303b"
        } else if (rate < 6) {
            return "#5369a2"
        } else if (rate < 8) {
            return "#1d8acd"
        } else if (rate < 9) {
            return "#2fc482"
        } else { //if(rate === 9 || rate ===10)
            return "#249563"
        }
    }

    useEffect(() => {
        fetch(URL_SHOP_GET_BY_ID + "/?id=" + id)
            .then(response => response.json())
            .then(data => {
                    console.log(data)
                    setData(data.data)
                    setImages([
                        {
                            thumbnail: GAMES_IMG_URL + "/400/" + data.data.image_name,
                            original: GAMES_IMG_URL + "/600/" + data.data.image_name,
                            //originalHeight : 250
                        },

                        ...data.data.tableGamesImgs.map((image: any) => {
                            return {
                                thumbnail: GAMES_IMG_URL + "/400/" + image.image_name,
                                original: GAMES_IMG_URL + "/600/" + image.image_name,
                                //originalHeight : 250
                            }
                        })
                    ])
                    //console.log(data.data)
                }
            )
    }, [id])


    function BreadcrumbExample(data: Product | null) {
        return (
            <Breadcrumb>
                <li className="breadcrumb-item"><Link to="/games">Настолки</Link></li>
                {/*{data?.types[0] && <Breadcrumb.Item href="#">{data.types[0]?.name}</Breadcrumb.Item>}*/}
                <Breadcrumb.Item active>{data?.name}</Breadcrumb.Item>
            </Breadcrumb>
        );
    }

    function renderDescr() {
        return {__html: data?.descr ?? ""};
    }

    return (
        <div className="h-100">
            <BackButton
                onClick={() => {
                    navigate("/games")
                }}
            />
            <div className="container-fluid ">
                <div className="">
                    {/*  {BreadcrumbExample(data)}*/}
                    <div>{/*className="card p-3"*/}
                        <div> {/*className="container-fluid"*/}
                            <div className="wrapper row">
                                <div className="col-md-6">
                                    <div className="wrapper product-view-gallery w-100">
                                        <ImageGallery items={images} showFullscreenButton={true}
                                                      additionalClass="w-100"/>
                                    </div>
                                </div>
                                <div className="details col">
                                    <div className="">
                                        <h3 className="product-title">{data?.name}</h3>
                                        {data?.name_rus ?? <h5 className="product-title">({data?.name_rus})</h5>}
                                        <p className="product-description">{data?.short_descr}</p>
                                        <Row>
                                            <Col md={"auto"} sm={6} xs={6} className="text-right">
                                                <FontAwesomeIcon icon={faChartSimple}/> Сложность:
                                            </Col>
                                            <Col md={"auto"} sm={6} xs={6} className="text-left">
                                                {
                                                    //@ts-ignore
                                                    ComplexityData[data?.complexity ?? 0]
                                                }
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={"auto"} sm={6} xs={6} className="text-right">
                                                <FontAwesomeIcon icon={faClock}/> Длительность:
                                            </Col>
                                            <Col md={"auto"} sm={6} xs={6} className="text-left">
                                                {
                                                    // @ts-ignore
                                                    DurationData[data?.duration]
                                                }
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={"auto"} sm={6} xs={6} className="text-right">
                                                <FontAwesomeIcon icon={faPeopleGroup}/> Участники:
                                            </Col>
                                            <Col md={"auto"} sm={6} xs={6} className="text-left">
                                                {data?.players_from} - {data?.players_to}
                                            </Col>
                                        </Row>

                                        {data?.tableGamesCoops[0] &&
                                            <Row>
                                                <Col md={"auto"} sm={6} xs={6} className="text-right">
                                                    <FontAwesomeIcon icon={faPeopleCarryBox}/> Кооператив:
                                                </Col>
                                                <Col md={"auto"} sm={6} xs={6} className="text-left">
                                                    {data.tableGamesCoops[0]?.name}
                                                </Col>
                                            </Row>
                                        }

                                        {data?.tableGamesThemes[0] &&
                                            <Row>
                                                <Col md={"auto"} sm={6} xs={6} className="text-right">
                                                    <FontAwesomeIcon icon={faMasksTheater}/> Жанр:
                                                </Col>
                                                <Col md={"auto"} sm={6} xs={6} className="text-left">
                                                    {/* {data.themes[0]?.name}*/}
                                                    {
                                                        data.tableGamesThemes.map((item: any) => (
                                                            <>
                                                                <span>{item?.name}</span><br/>
                                                            </>
                                                        ))
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                        {data?.tableGamesTypes[0] &&
                                            <Row>
                                                <Col md={"auto"} sm={6} xs={6} className="text-right">
                                                    <FontAwesomeIcon icon={faHashtag}/> Тип:
                                                </Col>
                                                <Col md={"auto"} sm={6} xs={6} className="text-left">
                                                    {
                                                        data.tableGamesTypes.map((item: any) => (
                                                            <>
                                                                <span>{item?.name}</span><br/>
                                                            </>
                                                        ))
                                                    }
                                                </Col>
                                            </Row>

                                        }
                                        <Row>
                                            <Col md={"auto"} sm={6} xs={6} className="text-right">
                                                <FontAwesomeIcon icon={faTable}/> Полка:
                                            </Col>
                                            <Col md={"auto"} sm={6} xs={6} className="text-left">
                                                №{data?.shelf_num}
                                            </Col>
                                        </Row>
                                        {
                                            (getBggRate().toString.length > 0) &&
                                            <Row>
                                                <Col md={"auto"} sm={6} xs={6} className="text-right">
                                                    <FontAwesomeIcon icon={faStar}/>Рейтинг BGG:
                                                </Col>
                                                <Col md={"auto"} sm={6} xs={6} className="text-left">
                                                    <span style={{color: getBggColor()}}>{getBggRate()}/10</span></Col>
                                                <a target="_blank" href={data?.bgg?.link} rel="noreferrer">
                                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare}/></a>
                                            </Row>
                                        }

                                        <br/>
                                        {
                                            (data?.price_day ?? 0) > 0 &&
                                            <PriceSelector product={data} onSelect={setSelectedPeriod}/>
                                        }
                                        <br/>
                                        <span className="price">
                                            Залог: <span>{data?.price_full}руб</span>
                                        </span>

                                        <Container className="text-center">
                                            <Row>
                                                <FontAwesomeIcon
                                                    className={data?.available_for_rent ? "text-success" : "text-danger"}
                                                    icon={data?.available_for_rent ? faCheck : faXmark}
                                                />

                                                <span>
                                                    {data?.available_for_rent ? "Доступно для аренды" : "Не доступно для аренды"}
                                                </span>
                                            </Row>
                                            <Row>
                                                <FontAwesomeIcon
                                                    className={data?.available_for_sale ? "text-success" : "text-danger"}
                                                    icon={data?.available_for_sale ? faCheck : faXmark}
                                                />
                                                {data?.available_for_sale ? "Доступно для покупки" : "Не доступно для покупки"}

                                            </Row>
                                        </Container>
                                        <br/>
                                        <div className="action">
                                            <button className={'btn btn-primary d-inline m-1 btn-xl add-to-cart'}
                                                    style={{
                                                        background: "var(--tg-theme-button-color)",
                                                        color: "var(--tg-theme-button-text-color)"
                                                    }}
                                                    disabled={!data?.available_for_rent}
                                                    onClick={() => setCheckoutModalShow(true)}
                                                    type="button">Арендовать
                                            </button>
                                            <Link className={'btn btn-primary m-1 btn-xl add-to-cart'}
                                                    style={{
                                                        background: "var(--tg-theme-button-color)",
                                                        color: "var(--tg-theme-button-text-color)"
                                                    }}
                                                  to={"/gameEvent/"+id}
                                                    onClick={() => setCheckoutModalShow(true)}
                                                    type="button">Собрать компанию
                                            </Link>
                                            <span>&nbsp;</span>
                                            {/* <button className="add-to-cart btn btn-dark"
                                                    disabled={!data.available_for_sale} type="button">Купить
                                            </button>*/}
                                        </div>
                                    </div>
                                    {
                                        data != null &&
                                        <CheckoutModal
                                            show={checkoutModalShow}
                                            period={selectedPeriod}
                                            model={data}
                                            onSubmit={(resp: any) => {
                                                setCheckoutModalShow(false)
                                                setStatusModalData(resp);
                                                setStatusModalShow(true);
                                            }}
                                            onHide={() => setCheckoutModalShow(false)}
                                        />
                                    }

                                    <br/>
                                    <StatusModal onHide={() => setStatusModalShow(false)}
                                                 show={statusModalShow} resp={statusModalData}/>
                                </div>
                            </div>

                            <Container className="text-left">
                                <Tabs defaultActiveKey="descr" className="mb-3">
                                    <Tab eventKey="descr" title="Описание">
                                        <p className="product-description" dangerouslySetInnerHTML={renderDescr()}/>
                                    </Tab>
                                    <Tab eventKey="profile" title="Правила"
                                         disabled={!((data?.game_rules?.length ?? 0) > 0)}>
                                        {
                                            ((data?.game_rules.length ?? 0) > 0) ?
                                                <p>
                                                    {/*   <a href={data.game_rules} >Открыть</a>*/}


                                                    {/*  <PDFViewer
                                                        withCredentials={true}
                                                        document={{
                                                            url: data.game_rules,
                                                        }}
                                                    />*/}

                                                    {/*  <div style={{overflow:'scroll',height:600}}>
                                                    <MobilePDFReader url={data.game_rules}/>
                                                </div>*/}

                                                    {/*  <object>
                                                        <embed src={data.game_rules} width="100%" height="500"/>
                                                    </object>
                                                  */}
                                                    {
                                                        /*
                                                        <object data= type="application/pdf">
                                                            <iframe width="100%" src="https://docs.google.com/viewer?url={data.game_rules}&embedded=true"></iframe>
                                                            </object>
                                                        */
                                                    }
                                                </p>
                                                : <p> Нет никаких правил</p>
                                        }
                                    </Tab>
                                    <Tab eventKey="contact" title="Продолжения/Дополнения"
                                         disabled={!((data?.parents?.length) ?? 0 > 0)}>
                                        <section className="section-products">
                                            <div className="row row-cols-1 row-cols-sm-1  row-cols-md-2 row-cols-lg-4">
                                                {
                                                    ((data?.children?.length ?? 0) > 0) ?
                                                        data?.children.map((item: any) => (
                                                            <ShopListMiniCard key={item.id} product={item}/>
                                                        ))
                                                        : <div/>
                                                }
                                                {
                                                    ((data?.parents?.length ?? 0) > 0) ?
                                                        data?.parents.map((item: any) => (
                                                            <ShopListMiniCard key={item.id} product={item}/>
                                                        ))
                                                        : <div/>
                                                }
                                            </div>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopView;