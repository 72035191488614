import React from "react";
import ShopList from "./ShopList";
import ShopSidebar from "./ShopSidebar";
import {Col, Row} from "react-bootstrap";

class Shop extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    /*
    * :root {
  --tg-theme-bg-color: #18222c;
  --tg-theme-text-color: #fff;
  --tg-theme-hint-color: #aaa;
  --tg-theme-button-color: #18222c;
  --tg-theme-button-text-color: #fff;
  --tg-theme-secondary-bg-color: #333;
    }
    */

    render() {
        return <div className="h-100">
            <div className="container-fluid">
                <div className="row">
                    <aside className="col-sm-2 filer-sidebar pt-2">
                        <ShopSidebar/>
                    </aside>
                    <div className="col">
                        <ShopList/>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Shop;