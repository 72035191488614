import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug} from "@fortawesome/free-solid-svg-icons";

class NotFound extends React.Component {
    render() {
        return (
            <div className="h-100">
                <h1>404</h1>
                <h5>Похоже это еще не работает...</h5>
                <p>Потом починим...</p>
                <FontAwesomeIcon size="3x" icon={faBug}/>
            </div>
        )
    }
}

export default NotFound;
