import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import TGButton from "../../components/Button/Button";

/*
menu - 📖 Меню
lk - 😺Личный кабинет
qr - 🖼 Qr код
games - 🎲 Настолки
events - 📢 Мероприятия
booking - 📅 Бронирование
chats - 💬 Чаты
help - 💡 Помощь
*/

const Menu = () => {
    return (
        <Container>
            <p>📖 Меню</p>
            <Row>
                <Col>
                    <TGButton className="disabled" to={"/lk"}>😺Личный кабинет</TGButton>
                </Col>
                <Col>
                    <TGButton className="disabled" to={"/lk"}>🖼 Qr код</TGButton>

                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <TGButton to="/games">🎲 Настолки</TGButton>
                </Col>
                <Col>
                    <TGButton to={"/events"}>📢 Мероприятия</TGButton>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <TGButton to="/booking">📅 Бронирование</TGButton>
                </Col>
                <Col>
                    <TGButton className="disabled" to={"/chats"}>💬 Чаты</TGButton>
                </Col>
            </Row>

            <Row>
                <Col>
                    <TGButton to="/help">💡 Помощь</TGButton>
                </Col>
            </Row>

        </Container>
    );
};
export default Menu;
