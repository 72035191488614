import React from "react";
import {GAMES_IMG_URL} from "../../../config/urls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import {Product} from "../../../models/product";
import DurationData from '../../../config/duration.json';
import {Col, Container, Row} from "react-bootstrap";


interface Props {
    product: Product,
}

class ModalGameCard extends React.Component<Props> {
    render() {
        return (
            /*<Link to={"/shop/" + this.props.product.id}>*/
            <Row>
                <Col>
                    {this.props.product.image_name
                        && <div className="product-image"
                                style={{backgroundImage: `url(${GAMES_IMG_URL}/400/${this.props.product.image_name})`}}/>
                    }
                    {!this.props.product.image_name && <div className="product-image"/>}
                    {/*  <ul>
                                    <li><a href="#"> <FontAwesomeIcon icon={faShoppingCart}/></a></li>
                                    <li><a href="#"> <FontAwesomeIcon icon={faHeart}/></a></li>
                                    <li><a href="#"> <FontAwesomeIcon icon={faExpand}/></a></li>
                    </ul>*/}
                </Col>
                <Col>
                    <div className="part-2">
                        <h3 className="product-title" style={{color: "var(--tg-theme-button-text-color)"}}>
                            {this.props.product.name}
                        </h3>
                        <p className="ellipse two-lines" style={{color: "var(--tg-theme-button-text-color)"}}>
                            {this.props.product.short_descr}
                        </p>
                        <Container style={{color: "var(--tg-theme-button-text-color)"}}>

                            <Row>
                                <FontAwesomeIcon icon={faPeopleGroup}/>
                                <span>&nbsp;{this.props.product.players_from} - {this.props.product.players_to}</span>
                            </Row>

                            <Row className="text-right">
                                {/*   <FontAwesomeIcon icon={faTable}/>
                                        <span>{this.props.product.shelf_num}</span>
                                */}
                                <FontAwesomeIcon icon={faClock}/>
                                &nbsp;
                                {
                                    // @ts-ignore
                                    DurationData[this.props.product.duration]
                                }
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default ModalGameCard;