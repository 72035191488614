//const PROD_HOST = "zdbot.zdver.com"
const API_SERVERS = ["localhost", "zdbot.zdver.com"]

//import 'dotenv/config'

export const Constants = {
    ApiVersion: "/rapi",

    publicImageViewPath: "https://zdbot.zdver.com/files/",

    //TOKEN: '5966231054:AAEp1eypzLGHJ9tuVIsER2M325eaCHUvSvY',
    //WEB_APP_URL: 'https://botfront.zdver.com/'
    //TOKEN: process.env.TELEGRAM_TOKEN,
    WEB_APP_URL: process.env.WEB_APP_URL,
    ZDVER_API_TOKEN: "s4blCFqgh3K11hsqQBTp31kdnTn21dpJ",//process.env.ZDVER_API_TOKEN,
    API_URL: "https://zdbot.zdver.com",
}

