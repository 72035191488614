import React from "react";
import {useSearchParams} from "react-router-dom";
import {useTelegram} from "../../hooks/useTelegram";

const Pay = () => {
    //22313
    const {tg} = useTelegram();
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("userId");
    console.log(userId);
    console.log(tg?.initDataUnsafe);
    console.log(tg?.user?.id);
    return (
        <div className="h-100">
            <h1>Пополнение баланса</h1>
            <pre>userID:{userId}</pre>
            <pre>tg.initData:{tg?.initData.toString()}</pre>
            <pre>tg.initDataUnsafe:{tg?.initDataUnsafe.toString()}</pre>
            <pre>tg?.initData?.user?.id:{tg?.initData?.user?.id?.toString()}</pre>
            <pre>tg?.initDataUnsafe?.user?.id:{tg?.initDataUnsafe?.user?.id?.toString()}</pre>

            <pre>tg.initData:{tg.toString()}</pre>
            <p>Оплата по карте пока не доступна</p>
            <p>Вы можете пополнить баланс за наличные на кассе</p>
        </div>
    )
}

export default Pay;
