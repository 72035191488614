import React from 'react';
import './Button.css';
import {Link} from "react-router-dom";

type Props = {
    to: string
    children: any,
    className?: string,
}

const TGButton = (props: Props) => {
    return (
        <Link to={props.to} className={'btn btn-xl btn-outline-success d-inline m-1 ' + props.className}
              style={{background: "var(--tg-theme-button-color)", color: "var(--tg-theme-button-text-color)"}}>
            {props.children}
        </Link>
    );
}

export default TGButton;
