import React from "react";
import {FieldType} from "../types/FieldType";
import BoolViewer from "../field_viewers/BoolViewer";
import RelationManyViewer from "../field_viewers/RelationManyViewer";
import BarCodeViewer from "../field_viewers/BarCodeViewer";
import RelationOne from "../field_viewers/RelationOne";
import {FieldViewProps} from "../types/FieldViewProps";
import MomentViewer from "../field_viewers/MomentViewer";
//import ReactMarkdown from "react-markdown";
//import {BarCodeViewer2} from "../field_viewers/BarCodeViewer2";


const fieldViewers: any = {
    [FieldType.Integer]: (props: FieldViewProps) => <span className="font-monospace text-info">{props.value}</span>,
    [FieldType.Bool]: (props: FieldViewProps) => <BoolViewer value={props.value}/>,
    [FieldType.Image]: (props: FieldViewProps) => <img alt="img" src={props.attr.params.url + props.value} width="50"
                                                       height="50"/>,
    [FieldType.Html]: (props: FieldViewProps) => <div dangerouslySetInnerHTML={{__html: props.value}}/>,
    //[FieldType.Html]: (props: Props) =>  <>{ReactHtmlParser(props.value)}</>,
    [FieldType.RelationMany]: (props: FieldViewProps) => <RelationManyViewer {...props}/>,
    [FieldType.BarCode]: (props: FieldViewProps) => <BarCodeViewer value={props.value}/>,
    [FieldType.RelationOneData]: (props: FieldViewProps) => <RelationOne  {...props}/>,
    [FieldType.MomentDate]: (props: FieldViewProps) => <MomentViewer {...props}/>,
    //[FieldType.MarkDown]: (props: FieldViewProps) => <ReactMarkdown children={props.value}/>,
    [FieldType.Custom]: (props: FieldViewProps) => props.attr.params(props.value),
};

function FieldViewer(props: FieldViewProps) {
    const viewer = fieldViewers[props.attr?.type ?? FieldType.Html] || ((props: FieldViewProps) =>
        <span>{props.value}</span>);
    return viewer(props);
}


/*function FieldViewer(props: Props) {

    switch (props.attr?.type) {
        case FieldType.integer:
            return <span className="font-monospace text-info">{props.value}</span>
        case FieldType.bool:
            return <BoolViewer value={props.value}/>
        case FieldType.image:
            return <img alt="img" src={props.attr.params.url + props.value} width="50" height="50"/>
        case FieldType.html:
            return <div dangerouslySetInnerHTML={{__html: props.value}}/>
        case FieldType.relationMany || FieldType.relationOLDDDDDD:
            return <RelationManyViewer value={props.value}
                                       attr={props.attr}
                                       modelName={props.modelName}
                                       params={props.params}/>
        case FieldType.barCode:
            return <BarCodeViewer value={props.value}/>

        case FieldType.relationOneData:
            return <RelationOne value={props.value} attr={props.attr} params={props.params}
                                modelName={props.modelName}/>
        case FieldType.custom:
            return props.attr.params(props.value)
        default:
            return <span>{props.value}</span>
    }
}*/


export default FieldViewer