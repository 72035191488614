import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {BackButton} from '@vkruglikov/react-telegram-web-app';
import {UserTableGameEventsModel} from "./_model";
import {Col, InputGroup, Row} from "react-bootstrap";
import {Product} from "../../models/product";
import {GAMES_IMG_URL, URL_SHOP_GET_BY_ID} from "../../config/urls";
import DatePicker from "react-datepicker";
import {addDays, addHours} from "date-fns";
import ModalGameCard from "../games/view/ModalGameCard";
import {InputField} from "../../crud";
import {CreateGameEvent} from "../../api/gameEvent";
import {useTelegram} from "../../hooks/useTelegram";

const GameEvent = () => {
    let {id} = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState<Product | null>(null);
    const [images, setImages] = useState<any>([])

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [playersFrom, setPlayersFrom] = useState<number>(0);
    const [playersTo, setPlayersTo] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [descr, setDescr] = useState<string>("");
    const {tg} = useTelegram();
    //const [checkoutModalShow, setCheckoutModalShow] = React.useState(false);
    //const [statusModalShow, setStatusModalShow] = React.useState(false);
    //const [statusModalData, setStatusModalData] = React.useState();
    //const [selectedPeriod, setSelectedPeriod] = React.useState(null);


    function calcMaxDate() {
        let _MaxDate = new Date();
        return addDays(_MaxDate, 30);
        // return _MaxDate;
    }

    function handleSubmit(event: any) {
        event.preventDefault();
        const _params: any = {
            user_id: 2,
            table_game_id: tg?.initData?.user?.id ?? 0,
            players_from: playersFrom,
            players_to: playersTo,
            name: name,
            descr: descr,
            short_descr: descr,
            from_dt: startDate,
            to_dt: endDate,
            status: 0, //0-создано, -1-удалено, 1-активно, 10-выключено
        }
        CreateGameEvent(_params)
            .then((resp) => {
                console.log(resp)
                if (resp.data.id) {
                    navigate("/gameEventCreated?ok=true")
                } else {
                    alert("Что-то сломалось")
                }

            }).catch(() => alert("Что-то сломалось"))
    }

    useEffect(() => {
        if (startDate >= endDate) {
            setEndDate(addHours(startDate, 2))
        }
    }, [startDate, endDate])

    useEffect(() => {
        fetch(URL_SHOP_GET_BY_ID + "/?id=" + id)
            .then(response => response.json())
            .then(_data => {
                    console.log(_data)
                    setData(_data.data)

                    setName(_data.data.name);
                    setPlayersFrom(_data.data?.players_from);
                    setPlayersTo(_data.data?.players_to);

                    setImages([
                        {
                            thumbnail: GAMES_IMG_URL + "/400/" + _data.data.image_name,
                            original: GAMES_IMG_URL + "/600/" + _data.data.image_name,
                            //originalHeight : 250
                        },

                        ..._data.data.tableGamesImgs.map((image: any) => {
                            return {
                                thumbnail: GAMES_IMG_URL + "/400/" + image.image_name,
                                original: GAMES_IMG_URL + "/600/" + image.image_name,
                                //originalHeight : 250
                            }
                        })
                    ])
                    //console.log(data.data)
                }
            )
    }, [id])


    return (
        <div className="h-100">
            <BackButton
                onClick={() => {
                    navigate("/games")
                }}
            />
            <div className="container ">
                <Row className="p-2 text-center">
                    <div className="alert alert-success bgTgBtn">
                        {
                            (data != null) && <ModalGameCard product={data}/>
                        }
                    </div>
                </Row>


                <Row className="p-2">
                    <InputField name={'name'}
                                model={UserTableGameEventsModel.info.name}
                                label={UserTableGameEventsModel.attrs.name.label}
                                onChange={(e: any) => setName(e.target.value)}
                                value={name}/>
                </Row>

                <Row>
                    <Col>
                        <InputField name={'players_from'}
                                    model={UserTableGameEventsModel.info.name}
                            // label={UserTableGameEventsModel.attrs.players_from.label}
                                    label={"Игроков: От"}
                                    onChange={(e: any) => setPlayersFrom(e.target.value)}
                                    value={playersFrom}/>
                    </Col>
                    <Col>
                        <InputField name={'players_to'}
                                    model={UserTableGameEventsModel.info.name}
                            //label={UserTableGameEventsModel.attrs.players_to.label}
                                    label={"До"}
                                    onChange={(e: any) => setPlayersTo(e.target.value)}
                                    value={playersTo}/>
                    </Col>

                </Row>


                {/*   <InputField name={'duration'}
                            model={UserTableGameEventsModel.info.name}
                            label={UserTableGameEventsModel.attrs.duration.label}
                            onChange={this.handleInputChange}
                            value={this.state.form.duration}/>*/}

                <Row className="p-2">
                    <InputGroup
                        className={'field-'}>
                        <InputGroup.Prepend>
                            <InputGroup.Text className={'control-label text-primary required'}>
                                Начало мероприятия
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <DatePicker
                            //utcOffset={0}
                            locale="ru"
                            placeholderText="Начало мероприятия"
                            className="form-control bgTgBtn"
                            required={true}
                            dateFormat="d MMMM yyyy HH:mm"  //dateFormat="d MMMM yyyy HH:mm"
                            minDate={new Date()}
                            maxDate={calcMaxDate()}
                            showTimeSelect
                            selected={startDate}
                            onSelect={(date: Date) => setStartDate(date)}
                            onChange={(date: Date) => setStartDate(date)
                            }
                        />
                    </InputGroup>


                </Row>
                <Row className="p-2">


                    <InputGroup
                        className={'field-'}>
                        <InputGroup.Prepend>
                            <InputGroup.Text className={'control-label text-primary required'}>
                                Конец мероприятия
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <DatePicker
                            //utcOffset={0}
                            locale="ru"
                            placeholderText="Конец мероприятия"
                            className="form-control bgTgBtn"
                            required={true}
                            dateFormat="d MMMM yyyy HH:mm"  //dateFormat="d MMMM yyyy HH:mm"
                            minDate={new Date()}
                            maxDate={calcMaxDate()}
                            showTimeSelect
                            selected={endDate}
                            onSelect={(date: Date) => setEndDate(date)}
                            onChange={(date: Date) => setEndDate(date)
                            }
                        />
                    </InputGroup>


                </Row>

                <button className={'btn btn-primary m-1 btn-xl add-to-cart'}
                        style={{
                            background: "var(--tg-theme-button-color)",
                            color: "var(--tg-theme-button-text-color)"
                        }}
                        onClick={handleSubmit}
                    //onClick={() => setCheckoutModalShow(true)}
                        type="button">Создать
                </button>
            </div>
        </div>
    )
}

export default GameEvent;