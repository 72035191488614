import React from "react";
import {filterStore} from "../store/FilterStore";
import {observer} from "mobx-react";

const RentStatusFilter = observer(() => {

    return (
        <div className="card">
            <div className="card-header">Наличие</div>
            <div className="">
                <div>
                    <input
                        type="radio"
                        value="all"
                        name="avail"
                        className="custom-checkbox"
                        id="all"
                        checked={(filterStore.avail === "all")}
                        onChange={(value: any) => filterStore.setAvail(value)}
                    /> <label className="form-check-label" htmlFor="all">Все наши настолки</label>
                </div>
                <div>
                    <input
                        type="radio"
                        value="rent"
                        name="avail"
                        id="rent"
                        className="custom-checkbox"
                        checked={(filterStore.avail === "rent")}
                        onChange={(value: any) => filterStore.setAvail(value)}
                    /> <label className="form-check-label" htmlFor="rent">Можно арендовать</label>
                </div>
                <div>
                    <input
                        type="radio"
                        value="place"
                        name="avail"
                        id="place"
                        checked={(filterStore.avail === "place")}
                        onChange={(value: any) => filterStore.setAvail(value)}
                    /> <label className="form-check-label" htmlFor="place">Поиграть у нас</label>
                </div>

                {/*  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="rent" name="rent" checked={filterStore.availRent}
                          />
                    <label className="form-check-label" htmlFor="rent">Для аренды</label>
                </div>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="zd" name="zd" checked={filterStore.availPlace}
                           onChange={(value: any) => filterStore.setAvailPlace(value)}/>
                    <label className="form-check-label" htmlFor="zd">В заведении</label>
                </div>*/}
            </div>
        </div>
    )
})
export default RentStatusFilter;