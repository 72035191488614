import React from "react";
import {filterStore} from "../store/FilterStore";
import MultiSelectFilter from "../components/filters/MultiSelectFilter";
import DurationData from '../../../config/duration.json';
import {defaultFilter} from "../../../models/product";


const DurationFilter = () => {
    function getComplexityData() {
        let complexFilter: defaultFilter[] = [];
        Object.keys(DurationData).map((key, i: any) =>
            // @ts-ignore
            complexFilter.push({name: DurationData[key], id: key})
        )
        return complexFilter;
    }

    function onSelect(ids: any) {
        filterStore.setDuration(ids)
    }

    return <MultiSelectFilter selectedIDs={filterStore.durationIds} data={getComplexityData()}
                              placeholder="Продолжительность" onSelect={onSelect}/>


}
export default DurationFilter;