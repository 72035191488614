import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {API_EVENT_WEEK} from "../../config/urls";
import InfiniteScroll from 'react-infinite-scroller';
import Spinner from "../../components/Spinner";
import FeedDay from "./FeedDay";


//class Feed extends React.Component<any, FeedState> {
const Feed = () => {
    const [events, setEvents] = useState<any>({})
    const [hasMore, setHasMore] = useState<boolean>(true)
    // const [page, setPage] = useState<number>(0)
    //const [loading, setLoading] = useState<boolean>(true);


    /*    useEffect(() => {
            fetch(API_EVENT_WEEK + "?page=" + 0)
                .then((response) => response.json())
                .then(data => {
                    setEvents(data["data"]);
                });
        }, [])*/


    function onNextPage(_page: number) {
        setHasMore(false);
        //console.log("to page " + _page + " | " + API_EVENT_WEEK + "?page=" + _page)
        fetch(API_EVENT_WEEK + "?page=" + _page)
            .then((response) => response.json())
            .then(data => {
                //console.log(data)
                setEvents({...events, ...data["data"]});
                setHasMore(true);
            });
        /*        if(_page == 6){

                setEvents([]);
                }*/
    }


    return (
        <div className="h-100">
            <div className="container">
                <pre>{events.length}</pre>
                <pre>{hasMore}</pre>
                {
                    <InfiniteScroll
                        pageStart={-1}
                        loadMore={onNextPage}
                        hasMore={hasMore}
                        loader={<Spinner/>}
                    >

                        {
                            Object.entries(events).map(([key, value]) => (
                                <FeedDay key={key} date={new Date(key)} events={value}/>
                            ))
                        }
                    </InfiniteScroll>
                }

                <div className="feed-past-events">
                    <NavLink className="feed-link" to="/past-events">Прошедшие мероприятия</NavLink>
                </div>
            </div>

        </div>
    );

}

export default Feed;
