import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {BackButton} from '@vkruglikov/react-telegram-web-app';
import {UserTableGameEventsModel} from "./_model";
import {Col, InputGroup, Row} from "react-bootstrap";
import {Product} from "../../models/product";
import {GAMES_IMG_URL, URL_SHOP_GET_BY_ID} from "../../config/urls";
import DatePicker from "react-datepicker";
import {addDays, addHours} from "date-fns";
import ModalGameCard from "../games/view/ModalGameCard";
import {InputField} from "../../crud";
import {CreateGameEvent} from "../../api/gameEvent";
import {useTelegram} from "../../hooks/useTelegram";

const GameEventCreated = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const startString = searchParams.get("ok")
    return (
        <div className="h-100">
            <BackButton onClick={() => navigate("/games")}/>
            <div className="container text-center">
                    <div className="alert alert-success bgTgBtn">
                        <h1>Создано новое мероприятие</h1>
                        <p>Вам отправленно сообщение с подробностями</p>
                    </div>


                <button className={'btn btn-primary m-1 btn-xl add-to-cart'}
                        style={{
                            background: "var(--tg-theme-button-color)",
                            color: "var(--tg-theme-button-text-color)"
                        }}
                        onClick={() => navigate("/games")}
                        type="button">Закрыть
                </button>
            </div>
        </div>
    )
}

export default GameEventCreated;