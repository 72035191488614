import axios from 'axios'
import stringify from 'qs-stringify'
import {Constants} from "../../utils/constants";

/**
 * Validating data by asking server for model validation.
 * @param {string} model - Name of model in Yii2 style
 * @param {string} name - Name of filed in model
 * @param {*} value - Value to validate
 * @param {function} onSuccess - Callback in case of success
 * @param {function} onError - Callback in case of error
 * @returns {Promise<void>}
 */

/*const validate = (model: string, name: string, value: any): Promise<any> => {
    const url = `${Constants.API_URL}/validate/model-input`;
    const data = { model, name, value };
    return createApiRequest<any>(HttpMethod.POST, url, stringify(data));
};*/

/*validate(model, name, value)
    .then((data) => {
        if (data !== '' && data.constructor === Object) {
            const event = data.data;
            if ('error' in event) {
                onError(event.error);
            } else {
                onSuccess(data);
            }
        } else {
            onError('Error while fetching events data!');
            console.log('Error while fetching events data!');
        }
    })
    .catch((error) => {
        console.log(error.message);
    });*/

async function validate(model: string, name: string, value: any, onSuccess: any, onError: any) {
    await axios({
        method: 'post',
        headers: {  'authorization': "Bearer " + Constants.ZDVER_API_TOKEN},
        url: Constants.API_URL + Constants.ApiVersion + '/validate/model-input',
        data: stringify({
            model: model,
            name: name,
            value: value
        }),
    }).then(function (response) {
        if (response.data !== '' && response.data.constructor === Object) {
            //if ('error' in event) {
            if (response.status !== 200) {
                onError(response.data.message)
            } else {
                onSuccess(response.data.message)
            }
        } else {
            onError('Validate request error')
            console.log('Validate request error')
        }
    })
        .catch(function (error) {
            onError(error.response.data.message)
        })
}

/**
 * Prettify label or make it from field name.
 * @param label
 * @param name
 * @returns {string|boolean}
 */
function prepareLabel(label: any, name: string) {
    if (label === false || label === '' || label === undefined) {
        let temp = name
        temp = temp.replace(/([-_][a-z])/gi, ($1) => {
            return $1.toUpperCase().replace('-', ' ').replace('_', ' ')
        })
        temp = temp.charAt(0).toUpperCase() + temp.slice(1)
        return temp
    } else {
        return label
    }
}

function classPostfix(validated: boolean | undefined) {
    return validated !== undefined
        ? validated
            ? 'is-valid'
            : 'is-invalid'
        : ''
}

/**
 * Create label from name.
 * @param name
 * @returns {string|boolean}
 */
function createLabel(name: string) {
    let temp = name;
    temp = temp.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', ' ').replace('_', ' ')
    })
    temp = temp.charAt(0).toUpperCase() + temp.slice(1)
    return temp;
}

export {validate, prepareLabel, createLabel, classPostfix}
