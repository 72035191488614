import React from "react";
import {Product} from "../../../models/product";
import {WithFormField} from "./mobx/form";
import {observer} from "mobx-react";
import {getPeriodName, rentPeriod} from "../../../models/rent_period";


interface Props {
    product: Product,
    onSelect: Function
    startDate: Date
    selected: WithFormField<number>
}


const PriceSelector2 = observer((props: Props) => {
    //this.state = {finalPrice: props.product.periods['day_50']}
    //const [finalPrice, setFinalPrice] = useState(props.product.periods['day_50']);

    const calcPrice = (period: number) => {
        props.onSelect(period);
        //setFinalPrice(getPeriodValue(period, props.product))
    }

    return (
        <>
            <div className="media-change">
                <div className="btn-group btn-group-sm btn-vertical">
                    {/* <div className="btn-group mr-2" role="group" aria-label="Price group">*/}
                    <button
                        type="button"
                        disabled={((props.startDate.getDay() === 6) || (props.startDate.getDay() === 0))}
                        className={(props.selected.value === 0) ? "btn btn-success" : "btn btn-info"}
                        style={{fontSize: "unset"}}
                        onClick={() => calcPrice(rentPeriod.day)}>{getPeriodName(rentPeriod.day)}
                    </button>
                    <button type="button"
                            className={(props.selected.value === 1) ? "btn btn-success" : "btn btn-info"}
                            disabled={((props.startDate.getDay() === 6) || (props.startDate.getDay() === 0))}
                            style={{fontSize: "unset"}}
                            onClick={() => calcPrice(rentPeriod.twoDay)}>{getPeriodName(rentPeriod.twoDay)}
                    </button>
                    <button type="button"
                            className={(props.selected.value === 2) ? "btn btn-success" : "btn btn-info"}
                            style={{fontSize: "unset"}}
                            onClick={() => calcPrice(rentPeriod.week)}>{getPeriodName(rentPeriod.week)}
                    </button>
                    <button type="button" id="weekend"
                            disabled={(!((props.startDate.getDay() === 6) || (props.startDate.getDay() === 0)))}
                            className={(props.selected.value === 3) ? "btn btn-success" : "btn btn-info"}
                            style={{fontSize: "unset"}}
                            onClick={() => calcPrice(rentPeriod.weekend)}>{getPeriodName(rentPeriod.weekend)}
                    </button>
                </div>
            </div>
        </>
    )
});


export default PriceSelector2;