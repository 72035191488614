import React from "react";
import {Accordion, Button, ButtonGroup, Card, Col, Row, useAccordionToggle} from "react-bootstrap";
import TGButton from "../../components/Button/Button";


function CustomToggle({children, eventKey}: any) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <button
            type="button"
            className="btn btn-xl btn-outline-success d-inline m-1"
            //style={{ backgroundColor: 'green' }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

class Booking extends React.Component {
    render() {
        return (
            <div className="h-100">
                <h1>Бронь / Аренда</h1>
                <Row className="justify-content-center">
                    <ButtonGroup>
                        <Button active>Большой зал
                            <input ref="input1" type="radio" name="radioButtonSet" value='input1' defaultChecked/>
                        </Button>
                        <Button>Пуфики
                            <input ref="input2" type="radio" name="radioButtonSet" value='input2'/>
                        </Button>
                    </ButtonGroup>
                </Row>

                <Row>
                    <Col>
                        <p>Вы можете забранировать стол, это бесплатно</p>
                        <p>Бронь не принимается "день в день"</p>
                        <TGButton to={"/booking"}>Забронировать стол</TGButton>
                    </Col>
                    <Col>
                        <p>Возможна аренда всего зала, в таком случае все обговарится индивидуально</p>
                        <TGButton to={"/booking"}>Арендовать зал</TGButton>
                    </Col>
                </Row>

                <br/>

                <Row className="justify-content-center">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <CustomToggle eventKey="0">Большой зал</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>ЗДесь будет фото БЗ и кол-во человек</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <CustomToggle eventKey="1">Пуфики</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>Фото пуфиков!</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Row>
            </div>
        )
    }
}

export default Booking;
